@import "../../utils/variable.scss";
.selectLabel{
    padding-bottom: 10px;
}
.mt_dropdown_container {  
  .mt_dropdown_options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 130px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out;
    background-color: $white;
    color: $secondary;
    border: 1px solid $fourth;
    &.open {
      opacity: 1;
      visibility: visible;
    }
    li {
      padding: 16px 0  0 16px;
      text-align: left;
      font-size: 14px;
      cursor: pointer;
      .mt_check{
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #888888;
        margin-right: 6px;
        &.checked{
          background-color: $primary;
        }
      }
      &:last-child{
        padding-bottom: 16px;
      }
    }
  }
}
