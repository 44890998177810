@import "../../utils/variable.scss";

.inputTag {
    margin-right: 10px !important;
    width: 100%;

    input {
        padding: 9px 10px;
        // border: 1px solid #888888;   
        background: #FFFFFF;
    }

    fieldset {
        border: 1px solid #888888;
    }
}

.textInputTag {
    p {
        padding: 0;
    }
    .Mui-error {
        fieldset {
            border: 1px solid $error;
        }
    }
}

.errorText {
    padding-top: 5px;
    color: $error;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.label {
    font-size: 16px;
    line-height: 18px;
    font-family: "Inter", sans-serif;
    padding-bottom: 15px;
    display: block;
}