@import "../../utils/variable.scss";


a{
  cursor: pointer;
}
.header_master_container {
  position: relative;
  .hamburder{
    position: absolute;
    top: 18px;
    left: 24px;
    z-index: 3;
    visibility: hidden;
       svg{
        width: 1.28571429em;
           fill: $error;
           cursor: pointer;
           path{
            stroke:$error;
            stroke-width: 3px;
           }
       }
  }
  .desk_wrapper {

    // sec1
    .sec1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      column-gap: 20px;
      width: 100%;
      padding: 26px 0;

      .s1_p1 {
        width: 280px;
        height: auto;

        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .s1_p2 {
        .wrapper {
          >div {
            &:nth-child(1) {
              background-color: $primary;
              color: $white;
              font-size: 16px;
              line-height: normal;
              padding: 6px 3px;
              cursor: pointer;
              white-space: nowrap;
              &:hover {
                text-decoration: underline;
              }
            }

            &:nth-child(2) {
              img {
                cursor: pointer;
              }
            }

            &:nth-child(3) {
              img {
                cursor: pointer;
              }
            }

            &:nth-child(4) {
              display: flex;
              align-items: center;
              column-gap: 4px;
              color: $primary;
              cursor: pointer;

              &:hover {
                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .s1_p3 {
        >div {
          &:nth-child(1) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 16px;

            >div {
              background-color: $primary;
              color: $white;
              padding: 6px 10px;
              cursor: pointer;
            }
          }

          &.s1_p3_search_blk {
            margin: 6px 0 20px;

            >div {

              input {
                width: 250px;
                background-color: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.2);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                transition: border 0.2s linear, box-shadow 0.2s linear;
                outline: none;
                padding: 2px 6px;

                &::placeholder {
                  color: #999999;
                }

                &:focus {
                  border-color: rgba(82, 168, 236, 0.8);
                  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
                }
              }
            }
          }


        }

        .cart_blk {
          >div {
            text-align: right;

          }

        }

        .empty_cart_placeholder {
          color: #a8a8a8;
          font-family: "sourcesanspro", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
          line-height: 1.5;

        }
      }
    }

    // sec 2
    .sec2 {
      background-color: $primary;
      min-height: 50px;

      .wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        // nav
        .desk_nav {
          >ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 50px;
            flex-wrap: wrap;

            >li {
              margin: 0 0.5rem;
              color: $white;
              position: relative;
                >a.active{
                  color: #3b3f4c;
                  border-color: #3b3f4c;
                }
              &:hover {
                >.desk_submenu {
                  opacity: 1;
                  visibility: visible;
                }
              }

              // submenu
              .desk_submenu {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                background-color: $primary;
                min-width: 180px;
                padding: 14px 6px 0;
                transition: opacity 0.3s ease-out, transform 0.3s ease-out;

                >li {
                  margin: 0.3126666667rem 0;
                  transition: background-color .35s ease-out;

                  >a {
                    font-size: 0.8rem;
                    padding: 2px 0;
                    display: block;
                  }
                  >a.active{
                    background-color: $white;
                    text-decoration: underline;
                    color: #333333;
                  }
                  &:not(.desk_hover_none >li):hover {
                    background-color: $white;
                    color: #333333;
                    text-decoration: underline;

                    >a {
                      color: #333333;
                    }
                  }
                }
              }

              //  desk with image sub menu
              .desk_image_submenu {
                display: grid;
                &.one{
                  grid-template-columns: repeat(4, 118px);

                }
                &.two{
                  grid-template-columns: repeat(1, 118px);
                  >li{
                    width: 130px;
                  }
                }
                grid-template-rows: auto;
                padding: 14px 12px 12px !important;
                column-gap: 12px;
                row-gap: 11px;
                right: 0 !important;
                left: initial !important;
                width: fit-content;
                 >li{
                  margin: 0;
                 
                  a{
                    >div{

                      background-color: $white;
                      img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                      .submenu_image_title{
                        color: $primary;
                        width: 100%;
                        text-align: center;
                        white-space: nowrap;
                      }
                    }

                  }
                 }
              }

              &:hover {
                >a {
                  color: #3b3f4c;
                  border-color: #3b3f4c;
      


                }
              }

              >a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 6px;
                white-space: nowrap;
                font-size: 0.8rem;
                border-bottom: 1px solid;
                transition: all 0.35s ease;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: bold;

                svg {
                  padding-top: 4px;
                  white-space: nowrap;

                }

                &:hover {}
              }
            }
          }
        }
      }
    }
  }

  // mob
  .mob_nav_link {
    li {
      cursor: pointer;

      a {
        &.active {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .header_master_container {
    .hamburder{
      visibility: visible;
    }
    .desk_wrapper{
      .sec1{
         flex-direction: column;
         justify-content: initial;
         row-gap: 26px;
         padding-left:  60px !important;
         .s1_p3{
          width: 100%;
          max-width: 380px;
          display: flex;
          flex-direction: column;
          >div{
            width: 100%;
          }
          .s1_p3_search_blk{
            width: 100%;
            input{
              width: 100% !important;
              max-width: 380px;
            }
          }
         }
      }
      .sec2{

        display: none;
      }
    }
  }

  
}

// media query
@media (max-width: 768px) {
 
}

@media (max-width: 480px) {
  .header_master_container {
  
    .desk_wrapper{
      .sec1{
 
         padding-left:  20px !important;
      }}}
}