@import "../../utils/variable.scss";
.accordian_container {
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  .acc_arrow.rotate {
    transform: rotate(90deg);
  }
  .accordian_heading_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    // border: 2px solid $dark_light_grey;
    padding: 0 13px;
    > p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $secondary;
      max-width: 316px;
      overflow-wrap: anywhere;
      hyphens: auto;
      margin-bottom: 0;
    }
    span {
      font-size: 24px;
      color: $dark_light_grey;
      font-weight: 700;
    }
  }
  &.collapse_open {
    // border: 2px solid $dark_light_grey;
    .accordian_heading_wrapper {
      border: none;
      // border-bottom:  2px solid $dark_light_grey !important;
      p{
        font-weight: 700;
      }
    }
  }
  //   collapse
  .accordian_collapse {
    transition: height 0.5s ease;
    overflow: hidden;
    .accoidian_collapse_content {
      padding: 0px 32px 12px;
      li {
        margin-bottom: 4px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: $secondary;
      }
    }
  }
  .li_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $cement_grey;
    padding: 8px 0;
    span:nth-child(1) {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
    span:nth-child(2) {
    }
  }
}
