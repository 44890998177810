@import "../../utils/variable.scss";
// type1
button.btn_normal_primary {
  height: 40px;
  min-width: 90px;
  background-color: $primary;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  padding: 0 10px;
  border: none;
  span{
    display: none !important;
  }
}
// type2
button.btn_arrow_btn_primary {
  height: 44px;
  min-width: 180px;
  background-color: $primary;
  color: $white;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 4px;
  justify-content: center;
  span{
    margin-top: 5px;
  }
}
button.btn_arrow_btn_white{
  height: 44px;
  width:176px;
  background-color: $white;
  color: $primary;
  padding: 0px 16px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  border: 1px solid $primary;
  display: flex;
  align-items: center;
  column-gap: 4px;
  justify-content: center;
  text-transform: capitalize;
  span{
    margin-top: 5px;
    svg{
      path{
        stroke: $primary;
      }
    }
  }
}
// type3
button.btn_normal_white {
  height: 40px;
  min-width: 90px;
  border: 1px solid $primary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  padding: 0 10px;
  background-color: $white;
  color: $primary;
  span{
    display: none !important;
  }
}
