body {
  margin: 0;
  font-family: "sourcesanspro", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Container
.cl_container {
  margin: auto !important;

  height: 100%;
  margin: 0;
  box-sizing: border-box;
}
// media query
@media (max-width: 992px) {
  .cl_container {
  }
}
@media (max-width: 768px) {
  .cl_container {
  }
}
@media (max-width: 480px) {
  .cl_container {
  }
}
