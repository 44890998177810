@import "../../utils/variable.scss";

.td_dropdown_container {
  position: relative;
  cursor: pointer;
  border: 1px solid $fourth;
  padding: 0 10px;
  width: 200px;
  height: 40px;
  box-sizing: border-box;
  .td_dropdown_header {
   
    width: 100%;
    height: 100%;
 
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0em;
    color: $secondary;
    text-transform: capitalize;
    column-gap: 20px;
    white-space: nowrap;
   
    span {
   
      transition: transform 0.3s ease-out;
      svg{
        transform: rotate(180deg);
      }
    }
  }
  .td_dropdown_options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 112px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out;
    background-color: $white;
    color: $secondary;
    border: 1px solid $fourth;
    &.open {
      opacity: 1;
      visibility: visible;
    }
    li {
      padding: 16px 0  0 16px;
      text-align: left;
      font-size: 14px;
      &:last-child{
        padding-bottom: 16px;
      }
      
    }
  }
}
