@import "../../utils/variable.scss";
.cl_home_master_container {
  // min-height: 100vh;
  background-color: $white;
  //padding: 72px 0 80px;
  margin: 0;
  // search
  .search_table_blk {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
  }
  h2 {
    color: $secondary;
  }
  .home_title_sub_content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 16px 0 32px;
    // max-width: 518px;
    color: $fourth;
    span {
      font-weight: 600;
      color: $secondary;
    }
  }
  //   hero banner content
  .home_sec_hero_content_banner {
    display: flex;
    align-items: flex-start;
    column-gap: 42px;
    padding-bottom: 30px;
    // justify-content: center;
    // content sec
    .hshc_btn_wrapper {
      display: flex;
      align-items: center;
      column-gap: 20px;
      button {
        width: 200px;
        height: 44px;
      }
    }
    .home_sec_hero_content {
      flex-basis: 45%;
      flex-shrink: 1;

      h1 {
        color: $secondary;
      }
      p {
        margin: 24px 0 0px;
        color: $fourth;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: justify;
        line-height: 24px;
      }
      //   img wrapper container
      .home_img_wrapper {
        display: flex;
        align-items: center;
        column-gap: 32px;
        margin: 26px 0 30px;
      }
    }
    //banner
    .home_sec_hero_banner {
      flex-basis: 55%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      column-gap: 24px;
      justify-content: flex-end;
      row-gap: 16px;
      flex-wrap: nowrap;
    
      > div {
        display: flex;
        align-items: flex-start;
        column-gap: 20px;
        row-gap: 20px;
        box-sizing: border-box;
        > p {
          flex-basis: 26%;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: $fourth;
          padding-top: 6px;
          span {
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: left;
            color: $third;
            display: block;
          }
        }
        >img{
          object-fit: cover;
          height:176px;
          flex-basis: 73%;
        }
        &:nth-child(1) {
          padding-left: 16px;
        }
        &:nth-child(2) {
          flex-direction: row-reverse;
        }
        &:nth-child(3) {
          padding-left: 16px;
        }
      }
    }
  }
  //Location
  .home_sec_location {
  }
  //Subscribe
  .home_subscribe_sec {
    // height: 248px;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
    background-color: $secondary;
    .home_subscribe_sec_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div:nth-child(1) {
        max-width: 600px;
        h2 {
          color: $white;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: $white;
        }
      }
    }
  }
  //donar site
  .home_donar_site {
    // padding: 64px 0;
    > button:nth-child(3) {
      padding: 0 20px;
      margin: 0 0 10px;
    }
    .home_title_sub_content {
      margin: 16px 0 12px;
    }
    .htsc_mb {
      margin-bottom: 16px !important;
    }
  }
}
.h_table_noteblk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  margin-bottom: 4px;
}
// _dow_cop_articles
.home_sec_dow_cop_articles {
  > h2 {
    margin-bottom: 30px;
  }

  .home_sec_dow_cop_articles_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    row-gap: 50px;
  }
}
//media query
@media (max-width: 1120px) {
  .cl_home_master_container {
    h1 {
      text-align: center;
    }
    .hshc_btn_wrapper {
      justify-content: center;
      > button {
        margin: 0 !important;
      }
    }
    .home_sec_hero_content_banner {
      flex-wrap: wrap;
      row-gap: 20px;
      > div {
        flex-basis: 100% !important;
      }
      .home_sec_hero_content {
        p {
          text-align: center;
        }
        .home_img_wrapper {
          justify-content: center;
        }
        button {
          margin: auto;
        }
      }
      // img
      .home_sec_hero_banner {
        justify-content: center !important;
      }
    }
  }
}
@media (max-width: 992px) {
  .cl_home_master_container {
    h1 {
      text-align: center;
    }
    .home_sec_hero_content_banner {
      flex-wrap: wrap;
      row-gap: 20px;
      > div {
        flex-basis: 100% !important;
      }
      .home_sec_hero_content {
        p {
          text-align: center;
        }
        .home_img_wrapper {
          justify-content: center;
        }
        button {
          margin: auto;
        }
      }
      // img
      .home_sec_hero_banner {
        justify-content: center !important;
      }
    }

    // subscribe
    .home_subscribe_sec_wrapper {
      flex-wrap: wrap;
      row-gap: 20px;
      justify-content: center !important;
      h2,
      p {
        text-align: center !important;
      }
    }
    .home_sec_hero_banner {
      flex-direction: column;
      column-gap: 20px;
      > div {
        transform: translateY(0) !important;
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        button {
          // transform: rotate(90deg);
          flex-shrink: 0;
        }
        img {
         
          height: 170px ;
          object-fit: cover;
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: row-reverse;
          img {
            align-self: end;
          }
        }
      }
    }
    .home_sec_location {
    }
  }
}
@media (max-width: 768px) {
  .cl_home_master_container {
    padding: 50px 0;
    // h2,.home_title_sub_content {
    //   text-align: center;
    // }
    .h_table_noteblk {
      flex-wrap: wrap;
      margin-bottom: 10px;
      .search_table_blk {
        width: 100%;
        justify-content: space-between;
        .inp_search_wrapper {
          input {
            width: 180px;
          }
        }
      }
    }
    .home_title_sub_content {
      margin: 20px 0;
    }
    .home_sec_hero_banner {
      flex-direction: column;
      column-gap: 20px;
      > div {
        transform: translateY(0) !important;
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        button {
          // transform: rotate(90deg);
          flex-shrink: 0;
        }
        img {
          // width: 75%;
          height: 170px;
          object-fit: cover;
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: row-reverse;
          img {
            align-self: end;
          }
        }
      }
    }
    .home_sec_location {
    }
    //donat site sec
    .home_donar_site {
      padding: 30px 0;
    }
  }
}
@media (max-width: 640px) {
  .home_sec_dow_cop_articles_wrapper {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr)) !important;
    row-gap: 30px !important;
  }
  .home_subscribe_sec {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
    h2 {
      font-family: Inter;
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }
    p {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
@media (max-width: 480px) {
  .hshc_btn_wrapper {
    .md_dropdown_container {
      width: inherit;
      flex-basis: 50% !important;
      flex-shrink: 1 !important;
    }
    > button {
      width: inherit !important;
      min-width: inherit;
      flex-basis: 50% !important;
      flex-shrink: 1 !important;
    }
  }
  .cl_home_master_container .home_sec_hero_content_banner .home_sec_hero_banner > div {
  flex-wrap: wrap;
  padding-left: 0 !important;
   >p{
        flex-basis: 100%;

   }
   img{
    flex-basis: 100%;
   }
  }
}
@media (max-width: 400px) {
  .cl_home_master_container {
    padding: 50px 0;
    // h2,.home_title_sub_content {
    //   text-align: center;
    // }
    .h_table_noteblk {
      flex-wrap: wrap;
      margin-bottom: 10px;
      .search_table_blk {
        width: 100%;
        justify-content: space-between;
        .inp_search_wrapper {
          input {
            width: 130px;
          }
        }
      }
    }
  }
}
