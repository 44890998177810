@import "../../utils/variable.scss";
.inp_search_wrapper {
    position: relative;
    
  input {
    height: 40px;
    width: 250px;
    padding-left: 40px;
    padding-right: 6px;
      border: 1px solid $fourth;
      outline: none;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      font-family: 'Poppins', sans-serif;
      color: $fourth;
    }
    &::-webkit-search-cancel-button {
      cursor: pointer;
    }
  }
  .inp_search_icon{
    position: absolute;
    z-index: 1;
    top: 54%;
    transform: translateY(-50%);
    left: 18px;
  }
}
