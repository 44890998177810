/* VARIABLE DECLARATIONS */
$primary:#B5BF00;
$secondary:#3B3F4C;
$third:#4D4D4D;
$fourth:#888888;
$error: #FF0000;
$white:#ffff;
 $light_grey:#F9F9F9;
 $dark_light_grey:#DFE2E8;
 $ash_primary:rgba(181, 191, 0, 0.1);
 $light_white_primary:#f8f9e6;
 $cement_grey:rgba(136, 136, 136, 1);
 $dark_faded_primary:rgba(120, 123, 70, 0.3);