.textareaWrap {
    text-align: left;
    margin-bottom: 30px;
    width: 100%;

    label {
        font-size: 16px;
        line-height: 18px;
        font-family: "Inter", sans-serif;
        padding-bottom: 15px;
        display: block;
    }

    textarea {
        width: 100%;
        border: 1px solid #888888;
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
    }
}
.errorArea {
    textarea {
        border: 1px solid red;
    }
}