@import "../../utils/variable.scss";
.footer_mastercontainer {
  .cl_logo_footer{
    cursor: pointer;
  }
  .ft_bold {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  ul {
    list-style-type: none;
  }

  // height: 630px;
  background-color: $secondary;
  // display: flex;
  // align-items: flex-start;
   padding: 80px 120px;
   box-sizing: border-box;
  color: $white;
  .footer_wrapper {
    box-sizing: border-box;
    // padding: 80px;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    column-gap: 32px;
    .mob_cpw{
      display:none;
    }
    // secetion one
    .footer_sec_one {
      max-width: 383px;

      .footer_content_sec_one {
        margin: 34px 0 16px;
        > p:nth-child(1) {
          margin-bottom: 34px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.10000000149011612px;
          text-align: left;
        }
      }
 
    }
         //   contacts
      .footer_section_one_contacts {
        margin: 24px 0 28px;
        li {
          margin-bottom: 8px;
        }
        a {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }
      }
    // section 2
    .footer_sec_two {
      // padding-top: 4px;
      flex-shrink: 0;
      .footer_quicklink_wrapper {
        h4 {
          font-family: "Poppins", sans-serif;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.10000000149011612px;
          text-align: left;
        }
        // links sec
        .footer_list_page {
          margin: 24px 0 56px;
          li {
            margin-bottom: 12px;
          }
        }
      }
   
    }
  }
     //   image wrapper
      .footer_img_wrapper {
        display: flex;
        align-items: center;
        column-gap: 60px;
        margin-top: 16px;
        row-gap: 16px;
        justify-content: center;
        flex-wrap: wrap;
      }
}
.copywrite {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  margin-top: 24px;
}
@media (max-width:840px){
  .footer_mastercontainer{
    height: auto !important;
    padding: 0 !important;
    .footer_wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px !important;
      .footer_sec_one{
        text-align: center;
        max-width: 100%;
        .footer_content_sec_one{
          margin-top: 28px !important;
        }
        p{
          text-align: center !important;
          margin-bottom: 28px !important;
        }
      }
      .ft_bold{
        text-align: center;
      }
      .cl_social_media_container{
        justify-content: center;
      }
    }
    .footer_quicklink_wrapper{
      display: none;
    }
    .footer_section_one_contacts{
      margin: 10px auto 26px !important;
    }
    .footer_img_wrapper{
      column-gap: 50px !important;
    }
    .desk_cpw{
      display: none !important;
    }
    .mob_cpw{
      display: block !important;
      text-align: center !important;
    }
    .footer_section_one_contacts li {
      text-align: center;
    }
  }
}
@media (max-width:400px){
  .footer_img_wrapper{
    flex-wrap: wrap;
    justify-content: center;
  }
}