@import "../utils/variable.scss";
.master_wrapper{
    // width: 100vw;
    // height: auto;
    // overflow: hidden;
    // display: flex;
    // align-self: start;
    .mob_overlay{
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
        transition: opacity .45s ease-out;

    }
    .mob_sidebar{

    }
    .main-page{
        // flex-shrink: 1;
        transition: transform .35s ease-out;
    }
}