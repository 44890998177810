@import "../../utils/variable.scss";
.container {
  max-width: 1440px;
  margin: 0 auto;
}

.wraper {
  text-align: center !important;
  margin: 0 auto;
  padding-top: 40px;
  max-width: 938px;
  .heading {
    //color: $secondary;
    color: #4d4d4d;
    text-transform: capitalize;
  }
  .donar_form_img {
    height: 388px;
    object-fit: cover;
  }
  > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: $fourth;
  }
  section {
    padding-top: 40px;
    padding-bottom: 50px;

    img {
      width: 100%;
    }
  }
}

.textCenter {
  text-align: center;
}
.radioBlkWrapper {
  display: flex;
  padding-bottom: 30px;
  label {
    line-height: 16px !important;
    text-align: left;
    padding: 0 16px 0 20px !important ;
  }
}
// wrapper
.c_error_checkblk {
  position: relative;
  padding-bottom: 16px;
  // error
  .c_check_error {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff0000;
    position: absolute;
    z-index: 1;
    bottom: 2px;
    left: 0;
    padding-bottom: 0 !important;
  }
  &.cdt_pb {
    margin-bottom: 30px !important;
  }
}
//agrre your term
.agree_terms_policy {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  column-gap: 10px;
  max-width: 100% !important;
  > p {
    padding-bottom: 0 !important;
    margin: inherit !important;
    max-width: 100% !important;
    overflow-wrap: break-word;
    span {
      color: $primary;
      font-weight: 700;
    }
  }
}
.mapTab {
  button {
    margin: 0 auto;
  }

  p {
    color: #888888;
    line-height: 24px;
    padding-bottom: 30px;
    a {
      color: $primary;
    }
  }
}

.fluid {
  width: 100% !important;
}

.formblk {
  width: 45%;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 30px;
  .mls_dropdown_container {
    width: 100%;
    margin-top: 3px;
    .mls_dropdown_header {
      min-height: 42px;
      box-sizing: border-box;
    }
  }
  .label {
    color: $secondary;
  }
}

.wrapperCol {
  display: flex;
  flex-wrap: wrap;
  .formblk:nth-child(even) {
    margin-right: 0px;
    width: 52%;
  }
}

.heading {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 35px;
  text-align: center !important;
}
@media (max-width: 1060px) {
  //agrre your term
  .agree_terms_policy {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    // padding-bottom: 30px;
    justify-content: flex-start;
    // max-width: 400px;
    // margin: auto;
    >span{
      line-height: 26px;
    }
    > p {
      padding-bottom: 0 !important;
      span {
        color: $primary;
        font-weight: 700;
      }
    }
  }
  .outlet {
    max-height: 1000px !important;
    padding: 40px 16px !important;

    button {
      margin-top: 10px !important;
    }
  }
  .wrapperCol {
    justify-content: center;
  }
  .formblk {
    // max-width: 400px !important;
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 20px !important;
  }
  .textareaWrap {
    box-sizing: border-box;
    textarea {
      box-sizing: border-box;
    }
  }
  .mapTab {
    text-align: center;
    p {
      max-width: 400px;
      margin: auto;
      a {
        color: $primary;
      }
    }
  }
  .mob_mr_0 {
    margin: auto;
  }
}
//pointer event none
.cl_form_field_diable{
  pointer-events: none;
}
