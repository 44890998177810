@import "../../utils/variable.scss";
.mls_check_box {
  width: 10px;
  height: 10px;
  background-color: $fourth;
  margin-right: 10px;
}
.selectLabel{
  padding-bottom: 10px;
}
.mls_dropdown_container {
  position: relative;
  cursor: pointer;
  width: 200px;
  border: 1px solid $fourth;
  .mls_dropdown_header {
    // width: 112px;
    // height: 40px;
    min-height: 40px;
    padding: 8px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    color: $secondary;
    text-transform: capitalize;
    column-gap: 20px;
    white-space: nowrap;
    .mls_header_wrapper{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap:10px;
      row-gap: 5px;
    }
    .mls_sel_data {
      // height: 20px;
      // width: 66px;
      border: 1px solid $primary;
      // box-sizing: border-box;
      padding: 5px 20px 5px 8px;
      position: relative;
     >span{
           position: absolute;
           top: 50%;
      transform: translateY(-50%) ;
           right: 5px;
       
     } 
    }
    > span {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) ;
      transition: transform 0.3s ease-out;
    }
  }
  .mls_dropdown_options {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    min-width: 112px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out;
    background-color: $white;
    border: 1px solid $fourth;
    color: $secondary;
    &.open {
      opacity: 1;
      visibility: visible;
    }
    li {
      padding: 16px 0 0 16px;
      text-align: left;
      font-size: 14px;
      .mls_check {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #888888;
        margin-right: 6px;
        &.checked {
          background-color: $primary;
        }
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }
  }
}
