@import url(https://fonts.googleapis.com/css?family=Roboto);
@import  "../../utils/variable.scss";
$md-radio-checked-color: #B5BF00;
$md-radio-border-color: #3B3F4C;
$md-radio-size: 20px;
$md-radio-checked-size: 10px; 
$md-radio-ripple-size: 15px;

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% { 
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.RadioButton {
    margin: 16px 0;

    input[type="radio"] {
        display: none;

        &:checked + label:before {
            border-color:$md-radio-border-color;
            animation: ripple 0.2s linear forwards;   
        }

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 0 25px;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        line-height: 16px;
            
        // &:before, &:after {
        //     position: absolute;            
        //     content: '';  
        //     transition: all .3s ease;
        //     transition-property: transform, border-color;
        // }
        &.active{
          &:before{
            background-color: $primary;
          }
        }

        &:before {
          position: absolute;            
            content: '';  
            transition: all .3s ease;
            
            left: 0;
            top: 0;
            width: 12px;
            height: 12px;
            border: 1.5px solid $md-radio-border-color;
        }

        // &:after {
        //   height: 12px;
        //   left: 1.5px;
        //   top: 1.5px;
        //     width:12px;
          
        //     transform: scale(0);
        //     background:$md-radio-checked-color;
        // }
    }
}
