@import "./utils/variable.scss";
/* font family */
// poppins - Inter-Hanken+Grotesk
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,500;0,600;1,300;1,700&family=Inter:wght@300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;900&display=swap');

//Style
* {
  margin: 0;
  padding: 0;
  //font-family: "Inter", sans-serif;
  font-family: "sourcesanspro", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  scroll-behavior: smooth;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button,
a {
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
}
//App Master Container
.cl_app_master_container {
  // min-height: 100vh;
  position: relative;
  overflow: hidden;
  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
  }
  h3 {
    font-size: 1.6rem; 
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;

  }
  h4 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
