@import "../../utils/variable.scss";

.padBlk {
    padding-right: 20px;
}

.flexEnd {
    justify-content: flex-end;
}

.blkContent {
    width: 100%;
}
.c_error_extra{
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
}
.detailForm {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    row-gap: 50px !important;

    .blkContent.lightBg {
    }

    input[type="text"]:disabled {
        background-color: #D9D9D9;
    }
}

.contentForm {
    width: 100%;
    margin-bottom: 30px;
}

.whiteBg {
    margin-left: 20px;
}

.lightBg {
    background: rgba(181, 191, 0, 0.05);
    padding: 40px 30px;

    .siteConent:last-child {
        padding-bottom: 0px;
    }
}

.siteConent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    div {
        width: 50%;
        font-size: 20px;
        line-height: 40px;
    }
}

.siteName {
    font-size: 1.6rem;
    line-height: 40px;
    font-weight: 700;
    color: $third;
}
.siteName_ref{
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    color: $third;
}
.siteHead {
    color: #3B3F4C;
    font-weight: 600;
}

.ds_home_sec_hero_banner {
    column-gap: 24px
                        !important;
    display: flex !important;
    flex-basis: 55% !important;
    flex-shrink: 0 !important;
    flex-wrap: nowrap !important;
    justify-content: flex-end !important;
    row-gap: 24px !important;
    .home_img_wrapper {
        text-align: center;
        >div:first-child {
        
            transform: translateY(16px);}
    }
}


// media
@media (max-width:1120px){
    .ds_home_sec_hero_banner {
        .home_img_wrapper{
            justify-content: center !important;

        }
    }
}
@media (max-width:992px) {
    .blkContent.whiteBg {
        margin-left: 0 !important;
    }

    .detailForm {
        button {
            margin: auto;
        }
    }

    .ds_home_sec_hero_banner {
        .home_img_wrapper {
            text-align: center;
            justify-content: center !important;
        }
    }
}

@media (max-width:768px) {
    .ds_home_sec_hero_banner {
        .home_img_wrapper {
            text-align: center;
            justify-content: center;
        }

        img {
            transform: none !important;
            width: 72px !important;
            height: 60px !important;
            object-fit: contain;
        }
    }
    .siteConent{
        div {
            width: 50%;
            font-size: 14px;
            line-height: 20px;
        }
    }
}
