@import "../../utils/variable.scss";
.cl_signup {
  width: 100%;
  height: 100%;
  padding: 16px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: $secondary;
  }
}
.cl_siup_error {
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff0000;
  &.check_er{
    font-size: 10px;
  }
}
.cl_signup_form_wrapper {
  button {
    margin: 26px auto 0;
  }
}
//form inside whole wrapper
.cl_siup_fields_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  .siup_note {
    font-size: 10px;
    color: $secondary;
    padding: 4px 4px 2px 2px;
    span {
      font-weight: 700;
    }
  }
  label {
    font-size: 10px;
    color: $secondary;
    margin-bottom: 6px;
    padding-left: 1px;
  }
}
//svg and inp blk
.cl_signup_inpblk {
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid $fourth;
  overflow: hidden;

  > div {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 18px;
      height: 18px;
      fill: $primary;
    }
  }
  input {
    padding: 0 12px 0 0px;
    flex-grow: 1;
    min-width: 290px;
    height: 100%;
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
  }
}
// check box wrapper
.cl_check_wrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
  label {
    font-size: 10px;
    color: $secondary;
    margin-bottom: 0;
  }
}
// media
@media (max-width: 768px) {
  .cl_signup_inpblk {
    input {
      min-width: 0px !important;
    }
  }
}
@media (max-width: 640px) {
  .cl_siup_fields_wrapper {
    grid-template-columns: 1fr;
  }
}
