@import "../../utils/variable.scss";
.selectLabel{
    padding-bottom: 10px;
}
.fd_dropdown_container {
  position: relative;
  cursor: pointer;
  border: 1px solid #888888;
  padding: 9px 10px;
  .fd_dropdown_header {
    // width: 112px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    color: $secondary;
    text-transform: capitalize;
    column-gap: 20px;
    white-space: nowrap;
    span {
      transition: transform 0.3s ease-out;
      svg{
        transform: rotate(180deg);
      }
    }
  }
  .fd_dropdown_options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    min-width: 112px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out;
    background-color: $white;
    color: $secondary;
    border: 1px solid $fourth;
    &.open {
      opacity: 1;
      visibility: visible;
    }
    li {
      padding: 16px 0  0 16px;
      text-align: left;
      font-size: 14px;
      .fd_check{
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #888888;
        margin-right: 6px;
        &.checked{
          background-color: $primary;
        }
      }
      &:last-child{
        padding-bottom: 16px;
      }
    }
  }
}
