@import "../../utils/variable.scss";
.cl_signin {
  width: 100%;
  height: 100%;
  padding: 16px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: $secondary;
  }
  .cl_forget_password {
    margin: 6px 0;
    margin-top: 12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: $secondary;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  //   form blk
  .cl_si_formblk {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  //   inp blk
  .cl_signin_inpblk {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid $fourth;
    overflow: hidden;
    max-width: 400px;
    > div {
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    input {
      padding: 0 12px 0 0px;
      flex-grow: 1;
       min-width: 290px;
      height: 100%;
      appearance: none;
      border: none;
      outline: none;
      background: transparent;
    }
  }
  button {
    margin: auto;
  }
  .cl_si_error {
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff0000;
  }
  .cl_signin_reg_link {
    color: $secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.02em;
    text-align: center;
    padding: 16px 0 10px;
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: -0.02em;
      text-align: left;
      transition: color 0.3s ease;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
  }
}
// media
@media  (max-width: 400px){
    .cl_signin_wrapper{
        width: 100%;
    }
    .cl_si_formblk{
        width: 99% ;
        margin: 0 auto 10px;
    }
    .cl_signin_inpblk{
      width: 100%;
      input{
        width: calc(100% - 32px
        );
      }
    }
} 