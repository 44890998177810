@import "../../utils/variable.scss";
// map style container
.map_sec_content_wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 32px;
  row-gap: 32px;
  width: 100%;
  > div:nth-child(1) {
    flex-basis: 79%;
    flex-shrink: 1;
  }
  .map_content {
    flex-basis: 20%;
    flex-shrink: 0;
    p {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: justify;
      color: $third;
      &:not(&:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
.map_container {
  // width: 700px;
  height: 700px;
  margin: 0 auto;
  .gm-ui-hover-effect {
    right: 0 !important;
    top: 0 !important;
  }
}
.wrapBlk {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  row-gap: 16px;
  flex-wrap: wrap;
  column-gap: 20px;
}
.clearLink {
  font-size: 12px;
  line-height: 40px;
  min-width: 120px;
  text-align: right;
  color: $primary;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 20px;
}
.clearWrapper {
  display: flex;
  align-items: flex-end;
  width: 75%;
}
.activeLabel {
  color: $primary;
  font-weight: 600;
}
.textBlk {
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
  > svg {
    margin-right: 10px;
  }
  span {
    width: 41.03px;
    margin-right: 10px;
    height: 20px;
    background-color: red;
    display: block;
  }
}
.leafletPopup {
  max-width: 200px;
  min-width: 100px;
}
.headRef {
  font-weight: bold;
  padding-bottom: 12px;
}
.addressHead {
  padding-bottom: 10px;
}
@media (max-width: 1024px) {
  .clearWrapper{
    width: 100%;
 }
 .map_sec_content_wrapper {
   flex-wrap: wrap;
   > div:nth-child(1) {
     flex-basis: 100%;
   }
   .map_content {
     flex-basis: 100%;
   }
 }
}
@media (max-width: 992px) {
  .wrapBlk {
    width: 100% !important;
    justify-content: flex-start;
    column-gap: 32px;
  }
  
}
@media (max-width: 640px) {
  .map_container {
    height: 350px;
  }
  .clearWrapper {
    align-items: flex-end !important;
    .clearLink {
      margin-bottom: 22px;
    }
  }
}
@media (max-width:400px){
  .clearWrapper{
    flex-wrap: wrap;
    justify-content: flex-start;
    .wrapBlk{
      margin-bottom: 0;
    }
    .clearLink{
      margin-bottom: 8px;
      text-align: left;
      margin-left: 4px;
    }
  }
}
