@import "../../../utils/variable.scss";
.filter_icon_wrappper{
  position: relative;
  .svgfilter {
    &.open {
      path {
        fill: $primary;
        stroke: $primary;
      }
    }
    path {
      fill: none;
      stroke: $secondary;
    }
  }
  
}
