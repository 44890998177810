@import "../../utils/variable.scss";

.filter_table_mastercontainer {
  .cl_edit_option{
    cursor: pointer;
    max-width: 50px;
  }
  .cl_edit_header{
    min-width: 50px;
  }
  .no_data_found{
    font-size: 18px;
    font-weight: 500;
    color: $primary;
    width: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }
  .ft_wrapper{
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
  }
  //filter section
  .filter_sec{
    position: relative;
  }

  .mtfilterblk{
    display: flex;
    flex-wrap: nowrap;
  }
  .ft {
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-collapse: collapse;

    th {
      a>span {
        margin-left: 4px;
        white-space: nowrap;
      }
    }

    tr:nth-child(even) {
      background-color: $light_grey;
    }

    &_down_arrow {
      transform: rotate(180deg);


    }
  }

  .linkBlk {
    cursor: pointer;
  }

  //   table head
  thead {
    tr {
      background-color: rgba(181, 191, 0, 0.1);

      th {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: left;
        color: $secondary;
        padding: 14px 0;
        padding-right: 16px;
        min-width: 90px;
        // &.th_min_wi

      }
    }
  }

  //   table body
  tbody {
    tr {
      td {
        padding: 16px 0;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: $secondary;
        padding-right: 16px;

        //  first column
        &:nth-child(1) {
          color: $primary;
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 14px;
          text-align: left
        }
      }
    }
  }

  //   col 1
  th:nth-child(1),
  td:nth-child(1) {
    // width: 62px;
    padding-left: 24px;
    padding-right: 24px;
    min-width: 70px;
  }

  //   col 2
  th:nth-child(2),
  td:nth-child(2) {
    min-width: 119px;
    max-width: 137px !important;
  }

  //   col 3
  th:nth-child(3),
  td:nth-child(3) {
    max-width: 245px;
    min-width: 170px;
  }

  //   col last child

  // th:last-child,
  // td:last-child {
  //   max-width: 230px;
  //   min-width: 170px;
  // }
  th.cl_notes,
  td.cl_notes {
    max-width: 230px;
    min-width: 170px;
  }

  // pagination
  .fl_pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 26px;
    column-gap: 30px;
    row-gap: 16px;
    flex-wrap: wrap;

    >div:nth-child(1) {
      color: $fourth;
      display: flex;
      align-items: center;
      column-gap: 6px;
      white-space: nowrap;

      >span {
        color: $secondary;

        &:nth-child(1) {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }

        &.fl_total_count {
          font-family: "Hanken Grotesk", sans-serif;
        }
      }
    }
  }

  .pagination-con {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    column-gap: 10px;
    justify-content: center;
    row-gap: 10px;
    padding-left: 0;

    .pagination-li {
      height: 23px;
      width: 28px;
      font-size: 14px;
      // border: 1px solid $light_grey;

      &:hover {
        .pagination-a {
          background-color: $primary;
          color: $white;
        }
      }
    }

    .pagination-a {
      width: 100%;
      height: 100%;
      color: $secondary;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
    }

    .pagination-prev-a {
      color: $light_grey;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
      text-decoration: none;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .pagination-next-a {
      color: $light_grey;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;
      text-decoration: none;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .pagination-prev {
      height: 23px;
      width: 28px;
      border: 1px solid $dark_light_grey;
      overflow: hidden;
    }

    .pagination-next {
      height: 23px;
      width: 28px;
      border: 1px solid $dark_light_grey;
      &.disabled{
        opacity: 0;
        visibility: hidden;
      }
    }

    .pagination-active {
      background-color: $primary;
      color: $white;
    }
  }

  // dropdown 
  .dropdown_container {
    .dropdown_header {
      width: 50px;
      border: 1px solid $dark_light_grey;
      justify-content: center !important;

      span {
        padding-bottom: 5px;

        svg {
          width: 9px;
          height: 4px;
        }
      }
    }

    .dropdown_options {
      width: 50px;
      text-align: center;
      min-width: 0;
      background-color: $white;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      li {
        color: $secondary;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        &:hover {
          background-color: $light_grey;

        }
      }
    }
  }



}


// media query

@media (max-width:992px){
  .ft_wrapper{
    overflow-x: scroll;
    width: 100%;

    .ft {
      width: 800px;

      //  white-space: nowrap;
      tr {

        th:nth-child(2),
        td:nth-child(2) {
          // min-width: 160px;
          // max-width: 170px !important;
          overflow-wrap: anywhere;
          white-space: normal;
        }
      }

    }
  }

}