.datePicker{
    .MuiFormControl-root{
        width: 100%;
    }
    input{
        padding: 10px;
    }
    fieldset{
        border: 1px solid #888888;
    }
}