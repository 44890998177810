@import "../../utils/variable.scss";

.mob_sidebar_container {
    width: 280px;
    // min-height: 100vh;
    transition: all .35s ease-out;
    position: relative;

    .mob_sidebar_wrapper {
        background-color: $primary;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: all .35s ease-out;
        overflow-x: hidden;
        overflow-y:auto ;
        .inner_wrapper{
            .mob_nav{
                >ul{
                    >li{
                        // position: relative;
                        >a{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            column-gap: 10px;
                            padding: 16px ;
                            font-size: 0.8rem;
                            color: $white;
                            white-space: nowrap;
                            >span{
                                &:nth-child(2){
                                    border: 1px solid #d2de00;
                                    width: 32px;
                                    height: 28px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #a2ab00;
                                    svg{
                                        width: 15px;
                                        height: 20px;
                                        
                                        path{
                                            opacity: .7;
                                            &:nth-child(1){
                                                color: #d2de00;
                                            }
                                        }
                                    }

                                }
                            }
                        
                        }
                        >a.active{
                            background: #8e9600;
                        }
                        &:hover{
                            >a{
                                background: #8e9600;
                            }
                        }
                    }
                }
                .mob_submenu{
                    position: absolute;
                    top: 0;
                    left: 103%;
                    width: 100%;
                    height: 100vh;
                    overflow-y: auto;
                    z-index: 4;
                    background: #b5bf00;
                    transition: left .2s ease-out;
                    &.mob_image_submenu{
                        padding-top: 50px;
                        &.one{

                            display: flex;
                            flex-wrap: wrap;
                            // column-gap: 10px;
                             row-gap: 10px;
                        }
                        &.two{
                            display: grid;
                            grid-template-columns: repeat(2,130px);
                            grid-template-rows: repeat(2,110px);
                            >li{
                                &:nth-child(1){
                                    width: 100%;

                                }
                            }
                        }
                     
                        >li{
                            width: 130px;
                            max-height: 100px;
                            .submenu_image_title{
                                text-align: center;
                                font-size: .8rem;
                                color: #858d00;
                                white-space: nowrap;
                            }
                            &:nth-child(1){
                                width: 100%;
                                height: 50px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 5;
                            }
                        }
                    }
                     &.mob_submenu_active{
                        left: 0 !important;
                     }

                    >li{
                        position: relative;
                        .back_block{
                            padding: 16px ;
                            font-size: 0.8rem;
                            color: $white;
                            white-space: nowrap;
                            background-color: #9da600;
                            svg{

                                transform: rotateZ(180deg);
                                opacity: .6;
                                cursor: pointer;
                          
                            }
                        }
                        >a{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            column-gap: 10px;
                            padding: 16px ;
                            font-size: 0.8rem;
                            color: $white;
                            white-space: nowrap;
                        }}
                }
            }
        }
    }
}