.outlet {
  background-color: #fff;
  border: 1px solid #888888;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 40px;
  padding: 60px 75px 40px;
}

.Tabs {
  .nav {
    display: flex;
    // padding-bottom: 10px;
    column-gap: 10px;
    justify-content: space-between;
    li {
      width: 225px;
      padding: 16px 10px;
      background: rgba(181, 191, 0, 0.1);
      // margin-right: 10px;
      cursor: pointer;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      //   text-align: left;
    }
    .active {
      background-color: #b5bf00;
      color: #ffffff;
      font-weight: 700;
    }
  }
}
@media (max-width: 640px) {
  .Tabs {
    .nav {
      column-gap: 10px;
      li {
        margin-right: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }
  }
}
