@import "../../utils/variable.scss";

.md_dropdown_container {
  position: relative;
  cursor: pointer;
  width: 200px;
  .md_dropdown_header {
    width: 100%;
    height: 44px;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: $white;
    column-gap: 20px;
    white-space: nowrap;
    text-transform: uppercase;
  }
  .md_dropdown_options {
    position: absolute;
    top: 101%;
    z-index: 1;
    width: 100%;
    min-width: 112px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease-out;
    background-color: $primary;
    color: $white;

    &.open {
      opacity: 1;
      visibility: visible;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-size: 14px;
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      text-align: center;
      &:hover {
        background-color: $dark_faded_primary;
      }
  
    }
  }
}
